<template>
  <div class="">
    <BreadCrumbs></BreadCrumbs>
    <div
      class="bg-gray-100 pt-2 sm:pt-0 pb-4 sm:bg-white flex flex-wrap justify-around md:justify-start mx-auto sm:px-4 md:px-16 lg:px-32 sm:mt-8 md:min-h-1/2-screen"
    >
      <CategoryTile v-for="tag in tags" :key="tag._id" :tag="tag" />
    </div>
  </div>
</template>

<script>
import CategoryTile from '@/components/categories/CategoryTile';
import BreadCrumbs from '@/components/shared/BreadCrumbs';

export default {
  computed: {
    tags() {
      if (this.$route.name === 'Categories') {
        return this.$store.getters['tags/getTags']({ type: 'Category' });
      } else {
        return this.$store.getters['tags/getTags']({
          type: 'Subcategory',
          value: this.$route.params.sub,
        });
      }
    },
  },
  components: {
    CategoryTile,
    BreadCrumbs,
  },
  metaInfo() {
    if (this.$route.name === 'Categories') {
      return {
        title: 'Online Hardware Supply & Tools Store | Page Custom Supply',
        meta: [
          {
            name: 'description',
            content:
              'Click here for Page Custom Supply’s broad inventory of Top Hardware Supply & Tools. PCS, a Top Online Hardware Supply Store for Individuals and Professionals!',
          },
          { name: 'keywords', content: 'online hardware supply' },
        ],
      };
    } else {
      return {
        title: `${this.$route.params.sub} - Categories`,
        meta: [
          { name: 'description', content: `See all products in our ${this.$route.params.sub}` },
          { name: 'keywords', content: 'online hardware supply' },
        ],
      };
    }
  },
  created() {
    if (this.$route.name === 'Subcategories') {
      this.$store.dispatch(
        'tags/getCategories',
        `type=Subcategory&category=${this.$route.params.sub}`
      );
    }
    this.$store.commit('filter/clearAllFilters');
  },
};
</script>

<style></style>
